var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      modifiers: _vm.modifiers,
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { class: _vm.wrapperClasses },
              [
                _vm.localePrefix
                  ? _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "apos-tooltip",
                            rawName: "v-apos-tooltip",
                            value: "apostrophe:cannotChangeSlugPrefix",
                            expression: "'apostrophe:cannotChangeSlugPrefix'",
                          },
                        ],
                        staticClass: "apos-input__slug-locale-prefix",
                        on: { click: _vm.passFocus },
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(_vm.localePrefix) + "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.type === "checkbox"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.next,
                          expression: "next",
                        },
                      ],
                      ref: "input",
                      class: _vm.classes,
                      attrs: {
                        placeholder: _vm.$t(_vm.field.placeholder),
                        disabled: _vm.field.readOnly,
                        required: _vm.field.required,
                        id: _vm.uid,
                        tabindex: _vm.tabindex,
                        type: "checkbox",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.next)
                          ? _vm._i(_vm.next, null) > -1
                          : _vm.next,
                      },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.$emit("return")
                        },
                        change: function ($event) {
                          var $$a = _vm.next,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.next = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.next = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.next = $$c
                          }
                        },
                      },
                    })
                  : _vm.type === "radio"
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.next,
                          expression: "next",
                        },
                      ],
                      ref: "input",
                      class: _vm.classes,
                      attrs: {
                        placeholder: _vm.$t(_vm.field.placeholder),
                        disabled: _vm.field.readOnly,
                        required: _vm.field.required,
                        id: _vm.uid,
                        tabindex: _vm.tabindex,
                        type: "radio",
                      },
                      domProps: { checked: _vm._q(_vm.next, null) },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.$emit("return")
                        },
                        change: function ($event) {
                          _vm.next = null
                        },
                      },
                    })
                  : _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.next,
                          expression: "next",
                        },
                      ],
                      ref: "input",
                      class: _vm.classes,
                      attrs: {
                        placeholder: _vm.$t(_vm.field.placeholder),
                        disabled: _vm.field.readOnly,
                        required: _vm.field.required,
                        id: _vm.uid,
                        tabindex: _vm.tabindex,
                        type: _vm.type,
                      },
                      domProps: { value: _vm.next },
                      on: {
                        keydown: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.$emit("return")
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.next = $event.target.value
                        },
                      },
                    }),
                _vm._v(" "),
                _vm.icon
                  ? _c(_vm.icon, {
                      tag: "component",
                      staticClass: "apos-input-icon",
                      attrs: { size: _vm.iconSize },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }