var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    {
      staticClass:
        "apos-admin-bar__control-set apos-admin-bar__control-set--title",
      attrs: { tag: "div", name: "flip" },
    },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: true,
              expression: "true",
            },
          ],
          key: "title",
          staticClass: "apos-admin-bar__title",
        },
        [
          _c("AposIndicator", {
            staticClass: "apos-admin-bar__title__indicator",
            attrs: {
              icon: "information-outline-icon",
              "fill-color": "var(--a-primary)",
              tooltip: _vm.docTooltip,
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "apos-admin-bar__title__document-title" }, [
            _vm._v("\n      " + _vm._s(_vm.context.title) + "\n    "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "apos-admin-bar__title__separator" }, [
            _vm._v("\n      —\n    "),
          ]),
          _vm._v(" "),
          !_vm.isUnpublished
            ? _c("AposContextMenu", {
                staticClass: "apos-admin-bar__title__document",
                attrs: {
                  button: _vm.draftButton,
                  menu: _vm.draftMenu,
                  disabled: _vm.hasCustomUi || _vm.isUnpublished,
                  "menu-offset": "13, 10",
                  "menu-placement": "bottom-end",
                },
                on: { "item-clicked": _vm.switchDraftMode },
              })
            : _c("AposLabel", {
                attrs: {
                  label: "apostrophe:draft",
                  modifiers: ["apos-is-warning", "apos-is-filled"],
                  tooltip: "apostrophe:notYetPublished",
                },
              }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }