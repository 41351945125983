var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-tree", class: { "apos-tree--nested": _vm.nested } },
    [
      _c("AposTreeHeader", {
        attrs: {
          headers: _vm.spacingRow,
          icons: _vm.icons,
          "spacer-only": true,
        },
        on: { calculated: _vm.setWidths },
      }),
      _vm._v(" "),
      _c("AposTreeHeader", {
        attrs: {
          headers: _vm.headers,
          icons: _vm.icons,
          "col-widths": _vm.colWidths,
          hidden: _vm.options.hideHeader,
        },
      }),
      _vm._v(" "),
      _c("AposTreeRows", {
        attrs: {
          rows: _vm.myItems,
          headers: _vm.headers,
          icons: _vm.icons,
          "col-widths": _vm.colWidths,
          level: 1,
          nested: _vm.nested,
          "list-id": "root",
          options: _vm.options,
          "tree-id": _vm.treeId,
        },
        on: { update: _vm.update },
        model: {
          value: _vm.checkedProxy,
          callback: function ($$v) {
            _vm.checkedProxy = $$v
          },
          expression: "checkedProxy",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }