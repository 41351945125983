var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-media-manager-display" }, [
    _c(
      "div",
      { staticClass: "apos-media-manager-display__grid" },
      [
        _vm.canEdit
          ? _c("AposMediaUploader", {
              attrs: {
                disabled: _vm.maxReached,
                action: _vm.moduleOptions.action,
                accept: _vm.accept,
              },
              on: {
                "upload-started": function ($event) {
                  return _vm.$emit("upload-started")
                },
                "upload-complete": function ($event) {
                  return _vm.$emit("upload-complete", $event)
                },
                "create-placeholder": function ($event) {
                  return _vm.$emit("create-placeholder", $event)
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.items, function (item) {
          return _c(
            "div",
            {
              key: _vm.idFor(item),
              staticClass: "apos-media-manager-display__cell",
              class: { "apos-is-selected": _vm.checked.includes(item._id) },
            },
            [
              _c(
                "div",
                { staticClass: "apos-media-manager-display__checkbox" },
                [
                  _c("AposCheckbox", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          item._id !== "placeholder" &&
                          !_vm.options.hideCheckboxes,
                        expression:
                          "item._id !== 'placeholder' && !options.hideCheckboxes",
                      },
                    ],
                    attrs: {
                      tabindex: "-1",
                      field: {
                        name: item._id,
                        hideLabel: true,
                        label: `Toggle selection of ${item.title}`,
                        disableFocus: true,
                        disabled:
                          _vm.options.disableUnchecked &&
                          !_vm.checked.includes(item._id),
                      },
                      choice: { value: item._id },
                    },
                    model: {
                      value: _vm.checkedProxy,
                      callback: function ($$v) {
                        _vm.checkedProxy = $$v
                      },
                      expression: "checkedProxy",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  ref: "btns",
                  refInFor: true,
                  staticClass: "apos-media-manager-display__select",
                  attrs: {
                    disabled:
                      item._id === "placeholder" ||
                      (_vm.options.disableUnchecked &&
                        !_vm.checked.includes(item._id)),
                  },
                  on: {
                    click: [
                      function ($event) {
                        if (
                          $event.ctrlKey ||
                          $event.shiftKey ||
                          $event.altKey ||
                          $event.metaKey
                        )
                          return null
                        return _vm.$emit("select", item._id)
                      },
                      function ($event) {
                        if (!$event.shiftKey) return null
                        return _vm.$emit("select-series", item._id)
                      },
                      function ($event) {
                        if (!$event.metaKey) return null
                        return _vm.$emit("select-another", item._id)
                      },
                    ],
                  },
                },
                [
                  item.dimensions
                    ? _c("div", {
                        staticClass: "apos-media-manager-display__placeholder",
                        style: _vm.getPlaceholderStyles(item),
                      })
                    : _c("img", {
                        staticClass: "apos-media-manager-display__media",
                        attrs: {
                          src: item.attachment._urls["one-sixth"],
                          alt: item.description || item.title,
                        },
                      }),
                ]
              ),
            ]
          )
        }),
        _vm._v(" "),
        _vm.items.length === 0
          ? _c(
              "div",
              {
                staticClass: "apos-media-manager-display__cell apos-is-hidden",
                attrs: { "aria-hidden": "true" },
              },
              [
                _c("button", {
                  ref: "btns",
                  staticClass: "apos-media-manager-display__select",
                  attrs: { disabled: "true" },
                }),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }