var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-area-menu--expanded",
    attrs: { modal: _vm.modal, "modal-title": "apostrophe:addContent" },
    on: {
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
      esc: _vm.close,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "main",
        fn: function () {
          return [
            _c("AposModalBody", {
              scopedSlots: _vm._u([
                {
                  key: "bodyMain",
                  fn: function () {
                    return _vm._l(_vm.groups, function (group, groupIndex) {
                      return _c(
                        "div",
                        { key: groupIndex, staticClass: "apos-widget-group" },
                        [
                          group.label
                            ? _c(
                                "h2",
                                { staticClass: "apos-widget-group__label" },
                                [_vm._v(_vm._s(group.label))]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              class: [
                                `apos-widget-group--${group.columns}-column${
                                  group.columns > 1 ? "s" : ""
                                }`,
                              ],
                            },
                            _vm._l(group.widgets, function (item, itemIndex) {
                              return _c(
                                "div",
                                {
                                  key: itemIndex,
                                  staticClass: "apos-widget",
                                  on: {
                                    click: function ($event) {
                                      return _vm.add(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "apos-widget__preview" },
                                    [
                                      _c("plus-icon", {
                                        staticClass: "apos-icon--add",
                                        attrs: { size: 20 },
                                      }),
                                      _vm._v(" "),
                                      item.previewUrl
                                        ? _c("img", {
                                            staticClass:
                                              "apos-widget__preview-image",
                                            attrs: {
                                              src: item.previewUrl,
                                              alt: `${item.name} preview`,
                                            },
                                          })
                                        : _vm.hasIcon(item)
                                        ? _c(item.previewIcon || item.icon, {
                                            tag: "component",
                                            staticClass:
                                              "apos-widget__preview--icon",
                                            attrs: { size: 25 },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "apos-widget__label" },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t(item.label)) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  item.description
                                    ? _c(
                                        "p",
                                        { staticClass: "apos-widget__help" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm.$t(item.description)) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      )
                    })
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }