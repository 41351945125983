var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModalToolbar", {
    attrs: { "class-name": "apos-manager-toolbar" },
    scopedSlots: _vm._u([
      {
        key: "leftControls",
        fn: function () {
          return [
            _vm.canSelectAll
              ? _c("AposButton", {
                  ref: "selectAll",
                  attrs: {
                    label: "apostrophe:select",
                    type: "outline",
                    "text-color": "var(--a-base-1)",
                    "icon-only": true,
                    icon: _vm.checkboxIcon,
                  },
                  on: { click: _vm.selectAll },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm._l(
              _vm.activeOperations,
              function ({ action, label, icon, operations, ...rest }) {
                return _c(
                  "div",
                  { key: action },
                  [
                    !operations
                      ? _c("AposButton", {
                          attrs: {
                            label: label,
                            "icon-only": true,
                            icon: icon,
                            disabled: !_vm.checkedCount,
                            type: "outline",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.confirmOperation({
                                action,
                                label,
                                ...rest,
                              })
                            },
                          },
                        })
                      : _c("AposContextMenu", {
                          attrs: {
                            button: {
                              label,
                              icon,
                              iconOnly: true,
                              type: "outline",
                            },
                            disabled: !_vm.checkedCount,
                            menu: operations,
                          },
                          on: {
                            "item-clicked": (a) =>
                              _vm.beginGroupedOperation(a, operations),
                          },
                        }),
                  ],
                  1
                )
              }
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "rightControls",
        fn: function () {
          return [
            !_vm.options.noPager && _vm.totalPages !== 0
              ? _c("AposPager", {
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "current-page": _vm.currentPage,
                  },
                  on: {
                    click: _vm.registerPageChange,
                    change: _vm.registerPageChange,
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.filters.length
              ? _c("AposFilterMenu", {
                  attrs: {
                    filters: _vm.filters,
                    choices: _vm.filterChoices,
                    values: _vm.filterValues,
                  },
                  on: { input: _vm.filter },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.hasSearch
              ? _c("AposInputString", {
                  ref: "search",
                  attrs: {
                    field: _vm.searchField.field,
                    status: _vm.searchField.status,
                    value: _vm.searchField.value,
                    modifiers: ["small"],
                  },
                  on: {
                    input: _vm.search,
                    return: function ($event) {
                      return _vm.search($event, true)
                    },
                  },
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }