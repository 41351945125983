var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-media-manager",
    attrs: { modal: _vm.modal, "modal-title": _vm.modalTitle },
    on: {
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
      esc: _vm.confirmAndCancel,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.relationshipField
          ? {
              key: "secondaryControls",
              fn: function () {
                return [
                  _c("AposButton", {
                    attrs: { type: "default", label: "apostrophe:cancel" },
                    on: { click: _vm.confirmAndCancel },
                  }),
                ]
              },
              proxy: true,
            }
          : {
              key: "secondaryControls",
              fn: function () {
                return [
                  _c("AposButton", {
                    attrs: { type: "default", label: "apostrophe:exit" },
                    on: { click: _vm.confirmAndCancel },
                  }),
                ]
              },
              proxy: true,
            },
        {
          key: "primaryControls",
          fn: function () {
            return [
              _c("AposUtilityOperations", {
                attrs: {
                  "module-options": _vm.moduleOptions,
                  "has-relationship-field": !!_vm.relationshipField,
                },
              }),
              _vm._v(" "),
              _vm.relationshipField
                ? _c("AposButton", {
                    attrs: {
                      type: "primary",
                      label: _vm.saveRelationshipLabel,
                      disabled: !!_vm.relationshipErrors,
                    },
                    on: { click: _vm.saveRelationship },
                  })
                : _vm._e(),
            ]
          },
          proxy: true,
        },
        {
          key: "leftRail",
          fn: function () {
            return [
              _c(
                "AposModalRail",
                [
                  _c("AposTagList", {
                    attrs: {
                      title: "apostrophe:filterByTag",
                      tags: _vm.tagList,
                    },
                    on: {
                      update: function ($event) {
                        return _vm.filter("_tags", $event)
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "main",
          fn: function () {
            return [
              _c("AposModalBody", {
                scopedSlots: _vm._u([
                  {
                    key: "bodyHeader",
                    fn: function () {
                      return [
                        _c("AposDocsManagerToolbar", {
                          attrs: {
                            "selected-state": _vm.selectAllState,
                            "total-pages": _vm.totalPages,
                            "current-page": _vm.currentPage,
                            filters: _vm.toolbarFilters,
                            labels: _vm.moduleLabels,
                            disable: _vm.relationshipErrors === "min",
                            "displayed-items": _vm.items.length,
                            "checked-count": _vm.checked.length,
                          },
                          on: {
                            "page-change": _vm.updatePage,
                            "select-click": _vm.selectClick,
                            search: _vm.search,
                            filter: _vm.filter,
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "bodyMain",
                    fn: function () {
                      return [
                        _c("AposMediaManagerDisplay", {
                          ref: "display",
                          attrs: {
                            accept: _vm.accept,
                            items: _vm.items,
                            "module-options": _vm.moduleOptions,
                            "can-edit": _vm.moduleOptions.canEdit,
                            "max-reached": _vm.maxReached(),
                            options: {
                              disableUnchecked: _vm.maxReached(),
                              hideCheckboxes: !_vm.relationshipField,
                            },
                          },
                          on: {
                            edit: _vm.updateEditing,
                            select: _vm.select,
                            "select-series": _vm.selectSeries,
                            "select-another": _vm.selectAnother,
                            "upload-started": function ($event) {
                              _vm.uploading = true
                            },
                            "upload-complete": _vm.completeUploading,
                            "create-placeholder": _vm.createPlaceholder,
                          },
                          model: {
                            value: _vm.checked,
                            callback: function ($$v) {
                              _vm.checked = $$v
                            },
                            expression: "checked",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "rightRail",
          fn: function () {
            return [
              _c("AposModalRail", { attrs: { type: "right" } }, [
                _c(
                  "div",
                  {
                    staticClass: "apos-media-manager__sidebar",
                    class: {
                      "apos-media-manager__sidebar--empty": !_vm.checked.length,
                    },
                  },
                  [
                    _c("AposMediaManagerEditor", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.editing,
                          expression: "editing",
                        },
                      ],
                      attrs: {
                        media: _vm.editing,
                        selected: _vm.selected,
                        "is-modified": _vm.isModified,
                        "module-labels": _vm.moduleLabels,
                      },
                      on: {
                        back: function ($event) {
                          return _vm.updateEditing(null)
                        },
                        saved: _vm.updateMedia,
                        modified: _vm.editorModified,
                      },
                    }),
                    _vm._v(" "),
                    _c("AposMediaManagerSelections", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.editing,
                          expression: "!editing",
                        },
                      ],
                      attrs: {
                        items: _vm.selected,
                        "can-edit": _vm.moduleOptions.canEdit,
                      },
                      on: { clear: _vm.clearSelected, edit: _vm.updateEditing },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }