var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-button-split", class: _vm.modifiers },
    [
      _c(
        "AposButton",
        _vm._b(
          {
            staticClass: "apos-button-split__button",
            attrs: {
              label: _vm.label,
              disabled: _vm.disabled,
              tooltip: _vm.tooltip,
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click", _vm.action)
              },
            },
          },
          "AposButton",
          _vm.button,
          false
        )
      ),
      _vm._v(" "),
      _c(
        "AposContextMenu",
        {
          ref: "contextMenu",
          staticClass: "apos-button-split__menu",
          attrs: {
            menu: _vm.menu,
            button: _vm.contextMenuButton,
            disabled: _vm.disabled,
            "menu-offset": "1, 10",
            "menu-placement": "bottom-end",
          },
          on: { open: _vm.focus },
        },
        [
          _c(
            "dl",
            {
              staticClass: "apos-button-split__menu__dialog",
              attrs: { role: "menu", "aria-label": _vm.menuLabel },
            },
            _vm._l(_vm.menu, function (item) {
              return _c(
                "button",
                {
                  key: item.action,
                  ref: "choices",
                  refInFor: true,
                  staticClass: "apos-button-split__menu__dialog-item",
                  class: { "apos-is-selected": item.action === _vm.action },
                  attrs: {
                    "aria-checked":
                      item.action === _vm.action ? "true" : "false",
                    role: "menuitemradio",
                    value: item.action,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectionHandler(item.action)
                    },
                  },
                },
                [
                  _vm.action === item.action
                    ? _c("AposIndicator", {
                        staticClass: "apos-button-split__menu__dialog-check",
                        attrs: {
                          icon: "check-bold-icon",
                          "icon-size": 18,
                          "icon-color": "var(--a-primary)",
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "dt",
                    { staticClass: "apos-button-split__menu__dialog-label" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t(item.label)) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  item.description
                    ? _c(
                        "dd",
                        {
                          staticClass:
                            "apos-button-split__menu__dialog-description",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t(item.description)) +
                              "\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }