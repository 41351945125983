var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "root" },
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "apos-slat-list",
            attrs: {
              tag: "ol",
              role: "list",
              list: _vm.next,
              move: _vm.onMove,
              id: _vm.listId,
            },
            on: {
              start: function ($event) {
                _vm.isDragging = true
              },
              end: function ($event) {
                _vm.isDragging = false
              },
            },
          },
          "draggable",
          _vm.dragOptions,
          false
        ),
        [
          _c(
            "transition-group",
            { attrs: { type: "transition", name: "apos-flip-list" } },
            _vm._l(_vm.next, function (item) {
              return _c("AposSlat", {
                key: item._id,
                staticClass: "apos-slat-list__item",
                class: {
                  "apos-slat-list__item--disabled": _vm.disabled,
                  "apos-input--error": _vm.duplicate,
                },
                attrs: {
                  item: item,
                  selected: _vm.selected === item._id,
                  disabled: _vm.disabled,
                  engaged: _vm.engaged === item._id,
                  parent: _vm.listId,
                  "slat-count": _vm.next.length,
                  removable: _vm.removable,
                  "has-relationship-schema": _vm.hasRelationshipSchema,
                  "editor-label": _vm.editorLabel,
                  "editor-icon": _vm.editorIcon,
                },
                on: {
                  remove: _vm.remove,
                  engage: _vm.engage,
                  disengage: _vm.disengage,
                  select: _vm.select,
                  move: _vm.move,
                  "item-clicked": function ($event) {
                    return _vm.$emit("item-clicked", item)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }