var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-area-modify-controls" },
    [
      _c(
        "AposButtonGroup",
        { attrs: { modifiers: _vm.groupModifiers } },
        [
          !_vm.foreign
            ? _c(
                "AposButton",
                _vm._b(
                  {
                    attrs: {
                      disabled: _vm.first || _vm.disabled,
                      tooltip:
                        !_vm.disabled && !_vm.first
                          ? "apostrophe:nudgeUp"
                          : null,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("up")
                      },
                    },
                  },
                  "AposButton",
                  _vm.upButton,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.foreign && !_vm.options.contextual
            ? _c(
                "AposButton",
                _vm._b(
                  {
                    attrs: {
                      disabled: _vm.disabled,
                      tooltip: "apostrophe:editWidget",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("edit")
                      },
                    },
                  },
                  "AposButton",
                  _vm.editButton,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.foreign
            ? _c(
                "AposButton",
                _vm._b(
                  {
                    attrs: { tooltip: "apostrophe:cut" },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("cut")
                      },
                    },
                  },
                  "AposButton",
                  _vm.cutButton,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.foreign
            ? _c(
                "AposButton",
                _vm._b(
                  {
                    attrs: {
                      disabled: _vm.disabled || _vm.maxReached,
                      tooltip: "apostrophe:duplicate",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("clone")
                      },
                    },
                  },
                  "AposButton",
                  _vm.cloneButton,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.foreign
            ? _c(
                "AposButton",
                _vm._b(
                  {
                    attrs: {
                      disabled: _vm.disabled,
                      tooltip: "apostrophe:delete",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("remove")
                      },
                    },
                  },
                  "AposButton",
                  _vm.removeButton,
                  false
                )
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.foreign
            ? _c(
                "AposButton",
                _vm._b(
                  {
                    attrs: {
                      disabled: _vm.last || _vm.disabled,
                      tooltip:
                        !_vm.disabled && !_vm.last
                          ? "apostrophe:nudgeDown"
                          : null,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("down")
                      },
                    },
                  },
                  "AposButton",
                  _vm.downButton,
                  false
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }