var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
      modifiers: _vm.modifiers,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return _vm._l(_vm.choices, function (choice) {
            return _c(
              "label",
              {
                key: choice.value,
                staticClass: "apos-choice-label",
                class: { "apos-choice-label--disabled": _vm.field.readOnly },
                attrs: { for: _vm.getChoiceId(_vm.uid, choice.value) },
              },
              [
                _c("input", {
                  staticClass:
                    "apos-sr-only apos-input--choice apos-input--radio",
                  attrs: {
                    type: "radio",
                    name: _vm.field.name,
                    id: _vm.getChoiceId(_vm.uid, choice.value),
                    tabindex: "1",
                    disabled: _vm.field.readOnly,
                  },
                  domProps: {
                    value: JSON.stringify(choice.value),
                    checked: _vm.next === choice.value,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.change($event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "apos-input-indicator",
                    attrs: { "aria-hidden": "true" },
                  },
                  [
                    _vm.next === choice.value
                      ? _c(
                          `${
                            _vm.next === choice.value
                              ? "check-bold-icon"
                              : "span"
                          }`,
                          { tag: "component", attrs: { size: 8 } }
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "apos-choice-label-text" },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t(choice.label)) + "\n        "
                    ),
                    choice.tooltip
                      ? _c("AposIndicator", {
                          staticClass: "apos-choice-label-info",
                          attrs: {
                            tooltip: choice.tooltip,
                            "icon-size": 14,
                            icon: "information-icon",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            )
          })
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }