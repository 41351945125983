var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposModal", {
    staticClass: "apos-array-editor",
    attrs: { modal: _vm.modal, "modal-title": _vm.modalTitle },
    on: {
      inactive: function ($event) {
        _vm.modal.active = false
      },
      "show-modal": function ($event) {
        _vm.modal.showModal = true
      },
      esc: _vm.confirmAndCancel,
      "no-modal": function ($event) {
        return _vm.$emit("safe-close")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "secondaryControls",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: { type: "default", label: "apostrophe:cancel" },
              on: { click: _vm.confirmAndCancel },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "primaryControls",
        fn: function () {
          return [
            _c("AposButton", {
              attrs: {
                type: "primary",
                label: "apostrophe:save",
                disabled: !_vm.valid,
              },
              on: { click: _vm.submit },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "leftRail",
        fn: function () {
          return [
            _c("AposModalRail", [
              _c(
                "div",
                { staticClass: "apos-modal-array-items" },
                [
                  _c(
                    "div",
                    { staticClass: "apos-modal-array-items__heading" },
                    [
                      _c(
                        "div",
                        { staticClass: "apos-modal-array-items__label" },
                        [
                          _vm.countLabel
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.countLabel) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.minLabel
                            ? _c(
                                "span",
                                {
                                  class: _vm.minError
                                    ? "apos-modal-array-min-error"
                                    : "",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.minLabel) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.maxLabel
                            ? _c(
                                "span",
                                {
                                  class: _vm.maxError
                                    ? "apos-modal-array-max-error"
                                    : "",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.maxLabel) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c("AposButton", {
                        staticClass: "apos-modal-array-items__add",
                        attrs: {
                          label: "apostrophe:addItem",
                          "icon-only": true,
                          icon: "plus-icon",
                          modifiers: ["tiny", "round"],
                          disabled: _vm.maxed || _vm.itemError,
                        },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.add.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("AposSlatList", {
                    staticClass: "apos-modal-array-items__items",
                    attrs: {
                      selected: _vm.currentId,
                      value: _vm.withLabels(_vm.next),
                    },
                    on: { input: _vm.update, select: _vm.select },
                  }),
                ],
                1
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "main",
        fn: function () {
          return [
            _c("AposModalBody", {
              scopedSlots: _vm._u([
                {
                  key: "bodyMain",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "apos-modal-array-item" }, [
                        _c(
                          "div",
                          { staticClass: "apos-modal-array-item__wrapper" },
                          [
                            _c(
                              "div",
                              { staticClass: "apos-modal-array-item__pane" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "apos-array-item__body" },
                                  [
                                    _vm.currentId
                                      ? _c("AposSchema", {
                                          ref: "schema",
                                          attrs: {
                                            schema: _vm.schema,
                                            "trigger-validation":
                                              _vm.triggerValidation,
                                            "utility-rail": false,
                                            "following-values":
                                              _vm.followingValues(),
                                            "conditional-fields":
                                              _vm.conditionalFields(),
                                            value: _vm.currentDoc,
                                            "server-errors":
                                              _vm.currentDocServerErrors,
                                            "doc-id": _vm.docId,
                                          },
                                          on: {
                                            input: _vm.currentDocUpdate,
                                            validate: _vm.triggerValidate,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }