import { render, staticRenderFns } from "./AposLoading.vue?vue&type=template&id=47a6d564&scoped=true&"
import script from "./AposLoading.vue?vue&type=script&lang=js&"
export * from "./AposLoading.vue?vue&type=script&lang=js&"
import style0 from "./AposLoading.vue?vue&type=style&index=0&id=47a6d564&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a6d564",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/cio_landing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47a6d564')) {
      api.createRecord('47a6d564', component.options)
    } else {
      api.reload('47a6d564', component.options)
    }
    module.hot.accept("./AposLoading.vue?vue&type=template&id=47a6d564&scoped=true&", function () {
      api.rerender('47a6d564', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/ui/apos/components/AposLoading.vue"
export default component.exports