var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-field__wrapper",
      class: {
        [`apos-field__wrapper--${_vm.field.type}`]: true,
        "apos-field__wrapper--full-width":
          _vm.field.type === "array" && _vm.field.style === "table",
      },
    },
    [
      _c(
        _vm.wrapEl,
        { tag: "component", class: _vm.classList },
        [
          _c(
            "div",
            { staticClass: "apos-field__info" },
            [
              _vm.field.label
                ? _c(
                    _vm.labelEl,
                    {
                      tag: "component",
                      staticClass: "apos-field__label",
                      class: { "apos-sr-only": _vm.field.hideLabel },
                      attrs: { for: _vm.uid },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t(_vm.label)) + "\n        "
                      ),
                      _vm.field.required
                        ? _c("span", { staticClass: "apos-field__required" }, [
                            _vm._v("\n          *\n        "),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      (_vm.field.help || _vm.field.htmlHelp) &&
                      _vm.displayOptions.helpTooltip
                        ? _c(
                            "span",
                            { staticClass: "apos-field__help-tooltip" },
                            [
                              _c("AposIndicator", {
                                staticClass: "apos-field__help-tooltip__icon",
                                attrs: {
                                  icon: "help-circle-icon",
                                  tooltip: _vm.field.help || _vm.field.htmlHelp,
                                  "icon-size": 11,
                                  "icon-color": "var(--a-base-4)",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displayOptions.changed
                        ? _c(
                            "span",
                            { staticClass: "apos-field__changed" },
                            [
                              _c("AposLabel", {
                                staticClass: "apos-field__changed__label",
                                attrs: {
                                  label: "apostrophe:changed",
                                  modifiers: [
                                    "apos-is-warning",
                                    "apos-is-filled",
                                  ],
                                  tooltip:
                                    "apostrophe:fieldHasUnpublishedChanges",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              (_vm.field.help || _vm.field.htmlHelp) &&
              !_vm.displayOptions.helpTooltip
                ? _c("p", {
                    staticClass: "apos-field__help",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t(_vm.field.help || _vm.field.htmlHelp)
                      ),
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("additional"),
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("body"),
          _vm._v(" "),
          _vm.errorMessage
            ? _c("div", { staticClass: "apos-field__error" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t(_vm.errorMessage)) + "\n    "
                ),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("secondary"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }