var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "apos-tiptap-select" },
    [
      _c(
        "select",
        {
          staticClass: "apos-tiptap-control apos-tiptap-control--select",
          domProps: { value: _vm.active },
          on: { change: _vm.setStyle },
        },
        _vm._l(_vm.options.styles, function (style, i) {
          return _c("option", { key: style.label, domProps: { value: i } }, [
            _vm._v("\n      " + _vm._s(style.label) + "\n    "),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _c("chevron-down-icon", {
        staticClass: "apos-tiptap-select__icon",
        attrs: { size: 11, "fill-color": "currentColor" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }