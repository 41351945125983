var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.editor
        ? _c(
            "bubble-menu",
            {
              staticClass: "bubble-menu",
              attrs: { "tippy-options": { duration: 100 }, editor: _vm.editor },
            },
            [
              _c(
                "AposContextMenuDialog",
                {
                  attrs: {
                    "menu-placement": "top",
                    "class-list": "apos-rich-text-toolbar",
                    "has-tip": false,
                    modifiers: ["unpadded"],
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "apos-rich-text-toolbar__inner" },
                    _vm._l(_vm.toolbar, function (item, index) {
                      return _c(
                        (_vm.tools[item] && _vm.tools[item].component) ||
                          "AposTiptapUndefined",
                        {
                          key: item + "-" + index,
                          tag: "component",
                          attrs: {
                            name: item,
                            tool: _vm.tools[item],
                            options: _vm.editorOptions,
                            editor: _vm.editor,
                          },
                        }
                      )
                    }),
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "apos-rich-text-editor__editor",
          class: _vm.editorModifiers,
        },
        [
          _c("editor-content", {
            class: _vm.editorOptions.className,
            attrs: { editor: _vm.editor },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showPlaceholder !== null && (!_vm.placeholderText || !_vm.isFocused)
        ? _c(
            "div",
            {
              staticClass: "apos-rich-text-editor__editor_after",
              class: _vm.editorModifiers,
            },
            [
              _vm._v(
                "\n    " +
                  _vm._s(_vm.$t("apostrophe:emptyRichTextWidget")) +
                  "\n  "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }