var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "apos-modal-tabs" }, [
    _c(
      "ul",
      { staticClass: "apos-modal-tabs__tabs" },
      _vm._l(_vm.tabs, function (tab) {
        return _c(
          "li",
          { key: tab.name, staticClass: "apos-modal-tabs__tab" },
          [
            _c(
              "button",
              {
                staticClass: "apos-modal-tabs__btn",
                attrs: {
                  id: tab.name,
                  "aria-selected": tab.name === _vm.currentTab ? true : false,
                },
                on: { click: _vm.selectTab },
              },
              [
                _vm._v("\n        " + _vm._s(_vm.$t(tab.label)) + "\n        "),
                _vm.tabErrors[tab.name] && _vm.tabErrors[tab.name].length
                  ? _c(
                      "span",
                      {
                        staticClass:
                          "apos-modal-tabs__label apos-modal-tabs__label--error",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.tabErrors[tab.name].length) +
                            " " +
                            _vm._s(
                              _vm.generateErrorLabel(
                                _vm.tabErrors[tab.name].length
                              )
                            ) +
                            "\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }