var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-admin-bar-wrapper",
      class: _vm.themeClass,
      attrs: { "data-apos-test": "adminBar" },
    },
    [
      _c("div", { ref: "spacer", staticClass: "apos-admin-bar-spacer" }),
      _vm._v(" "),
      _c(
        "nav",
        { ref: "adminBar", staticClass: "apos-admin-bar" },
        [
          _c(
            "div",
            { staticClass: "apos-admin-bar__row" },
            [
              _c("AposLogoPadless", { staticClass: "apos-admin-bar__logo" }),
              _vm._v(" "),
              _c("TheAposAdminBarMenu", { attrs: { items: _vm.items } }),
              _vm._v(" "),
              _vm.hasLocales() ? _c("TheAposAdminBarLocale") : _vm._e(),
              _vm._v(" "),
              _c("TheAposAdminBarUser", {
                staticClass: "apos-admin-bar__user",
                attrs: { "data-apos-test": "authenticatedUserMenuTrigger" },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("TheAposContextBar", { on: { mounted: _vm.setSpacer } }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }