var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.fieldStyle === "table" ? "tr" : "div",
    { tag: "component", staticClass: "apos-schema" },
    [
      _vm._t("before"),
      _vm._v(" "),
      _vm._l(_vm.schema, function (field) {
        return _c(
          _vm.fieldStyle === "table" ? "td" : "div",
          {
            key: field.name,
            tag: "component",
            attrs: { "data-apos-field": field.name },
          },
          [
            _c(_vm.fieldComponentMap[field.type], {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.displayComponent(field.name),
                  expression: "displayComponent(field.name)",
                },
              ],
              ref: field.name,
              refInFor: true,
              tag: "component",
              attrs: {
                "following-values": _vm.followingValues[field.name],
                "condition-met": _vm.conditionalFields[field.name],
                field: _vm.fields[field.name].field,
                modifiers: _vm.fields[field.name].modifiers,
                "display-options": _vm.getDisplayOptions(field.name),
                "trigger-validation": _vm.triggerValidation,
                "server-error": _vm.fields[field.name].serverError,
                "doc-id": _vm.docId,
                generation: _vm.generation,
              },
              model: {
                value: _vm.fieldState[field.name],
                callback: function ($$v) {
                  _vm.$set(_vm.fieldState, field.name, $$v)
                },
                expression: "fieldState[field.name]",
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm._t("after"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }