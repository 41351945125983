var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "apos-area",
      class: _vm.themeClass,
      attrs: { "data-apos-area": _vm.areaId },
    },
    [
      _vm.next.length === 0 && !_vm.foreign
        ? _c(
            "div",
            { staticClass: "apos-empty-area" },
            [
              _vm.isEmptySingleton
                ? [
                    _c("AposButton", {
                      attrs: {
                        label: {
                          key: "apostrophe:addWidgetType",
                          label: _vm.$t(_vm.contextMenuOptions.menu[0].label),
                        },
                        disabled: _vm.field && _vm.field.readOnly,
                        type: "primary",
                        icon: _vm.icon,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.add({
                            index: 0,
                            name: _vm.contextMenuOptions.menu[0].name,
                          })
                        },
                      },
                    }),
                  ]
                : [
                    _c("AposAreaMenu", {
                      attrs: {
                        "context-menu-options": _vm.contextMenuOptions,
                        empty: true,
                        index: 0,
                        options: _vm.options,
                        "max-reached": _vm.maxReached,
                        disabled: _vm.field && _vm.field.readOnly,
                        "widget-options": _vm.options.widgets,
                      },
                      on: { add: _vm.add },
                    }),
                  ],
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "apos-areas-widgets-list" },
        _vm._l(_vm.next, function (widget, i) {
          return _c("AposAreaWidget", {
            key: widget._id,
            attrs: {
              "area-id": _vm.areaId,
              widget: widget,
              generation: _vm.generation,
              i: i,
              options: _vm.options,
              next: _vm.next,
              "doc-id": _vm.docId,
              "context-menu-options": _vm.contextMenuOptions,
              "field-id": _vm.fieldId,
              disabled: _vm.field && _vm.field.readOnly,
              "widget-hovered": _vm.hoveredWidget,
              "non-foreign-widget-hovered": _vm.hoveredNonForeignWidget,
              "widget-focused": _vm.focusedWidget,
              "max-reached": _vm.maxReached,
              rendering: _vm.rendering(widget),
            },
            on: {
              up: _vm.up,
              down: _vm.down,
              remove: _vm.remove,
              cut: _vm.cut,
              copy: _vm.copy,
              edit: _vm.edit,
              clone: _vm.clone,
              update: _vm.update,
              add: _vm.add,
            },
          })
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }