var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposInputWrapper", {
    attrs: {
      modifiers: _vm.modifiers,
      field: _vm.field,
      error: _vm.effectiveError,
      uid: _vm.uid,
      "display-options": _vm.displayOptions,
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "apos-input-wrapper" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.next.url,
                      expression: "next.url",
                    },
                  ],
                  class: _vm.classes,
                  attrs: {
                    type: "url",
                    placeholder: _vm.$t(_vm.field.placeholder),
                    disabled: _vm.field.readOnly,
                    required: _vm.field.required,
                    id: _vm.uid,
                    tabindex: _vm.tabindex,
                  },
                  domProps: { value: _vm.next.url },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.next, "url", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _vm.icon
                  ? _c(_vm.icon, {
                      tag: "component",
                      staticClass: "apos-input-icon",
                      attrs: { size: _vm.iconSize },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.error && _vm.oembedResult.html
                  ? _c("div", {
                      staticClass: "apos-input__embed",
                      class: { "apos-is-dynamic": !!_vm.dynamicRatio },
                      style: {
                        paddingTop:
                          _vm.dynamicRatio && `${_vm.dynamicRatio * 100}%`,
                      },
                      domProps: { innerHTML: _vm._s(_vm.oembedResult.html) },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }