var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: { name: _vm.transitionType, duration: 250 },
      on: { enter: _vm.onEnter, leave: _vm.onLeave },
    },
    [
      _vm.modal.active
        ? _c(
            "section",
            {
              ref: "modalEl",
              class: _vm.classes,
              attrs: {
                role: "dialog",
                "aria-modal": "true",
                "aria-labelledby": _vm.id,
              },
            },
            [
              _c("transition", { attrs: { name: _vm.transitionType } }, [
                _vm.modal.showModal
                  ? _c("div", {
                      staticClass: "apos-modal__overlay",
                      on: { click: _vm.close },
                    })
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                {
                  attrs: { name: _vm.transitionType },
                  on: {
                    "after-leave": function ($event) {
                      return _vm.$emit("inactive")
                    },
                  },
                },
                [
                  _vm.modal.showModal
                    ? _c(
                        "div",
                        {
                          staticClass: "apos-modal__inner",
                          class: _vm.innerClasses,
                          attrs: { "data-apos-modal-inner": "" },
                        },
                        [
                          _vm.modal.busy
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "apos-modal__busy" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "apos-modal__busy-text" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(_vm.modal.busyTitle) +
                                            "\n            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("AposSpinner", {
                                      staticClass: "apos-busy__spinner",
                                      attrs: { weight: "heavy" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                !_vm.modal.disableHeader
                                  ? _c(
                                      "header",
                                      { staticClass: "apos-modal__header" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "apos-modal__header__main",
                                          },
                                          [
                                            _vm.hasSecondaryControls
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "apos-modal__controls--secondary",
                                                  },
                                                  [_vm._t("secondaryControls")],
                                                  2
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "h2",
                                              {
                                                staticClass:
                                                  "apos-modal__heading",
                                                attrs: { id: _vm.id },
                                              },
                                              [
                                                _vm.modal.a11yTitle
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "apos-sr-only",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                _vm.modal
                                                                  .a11yTitle
                                                              )
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.$t(_vm.modalTitle)
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.hasBeenLocalized ||
                                            _vm.hasPrimaryControls
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "apos-modal__controls--header",
                                                  },
                                                  [
                                                    _vm.hasBeenLocalized
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "apos-modal__locale",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "apos-modal__locale-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "apostrophe:locale"
                                                                      )
                                                                    ) +
                                                                    ":\n                  "
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "apos-modal__locale-name",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                    " +
                                                                    _vm._s(
                                                                      _vm.currentLocale
                                                                    ) +
                                                                    "\n                  "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.hasPrimaryControls
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "apos-modal__controls--primary",
                                                          },
                                                          [
                                                            _vm._t(
                                                              "primaryControls"
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm.hasBreadcrumbs
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "apos-modal__breadcrumbs",
                                              },
                                              [_vm._t("breadcrumbs")],
                                              2
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "apos-modal__main",
                                    class: _vm.gridModifier,
                                  },
                                  [
                                    _vm.hasLeftRail
                                      ? _vm._t("leftRail")
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._t("main"),
                                    _vm._v(" "),
                                    _vm._t("rightRail"),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.hasFooter
                                  ? _c(
                                      "footer",
                                      { staticClass: "apos-modal__footer" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "apos-modal__footer__inner",
                                          },
                                          [_vm._t("footer")],
                                          2
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                        ],
                        2
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }