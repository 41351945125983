var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("AposButton", {
    staticClass: "apos-rich-text-editor__control",
    class: { "apos-is-active": _vm.active },
    attrs: {
      type: "rich-text",
      label: _vm.tool.label,
      "icon-only": !!_vm.tool.icon,
      icon: _vm.tool.icon || false,
      "icon-size": 16,
      modifiers: ["no-border", "no-motion"],
    },
    on: { click: _vm.click },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }