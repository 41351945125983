import { render, staticRenderFns } from "./AposAvatar.vue?vue&type=template&id=107c48b7&scoped=true&"
import script from "./AposAvatar.vue?vue&type=script&lang=js&"
export * from "./AposAvatar.vue?vue&type=script&lang=js&"
import style0 from "./AposAvatar.vue?vue&type=style&index=0&id=107c48b7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "107c48b7",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/cio_landing/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('107c48b7')) {
      api.createRecord('107c48b7', component.options)
    } else {
      api.reload('107c48b7', component.options)
    }
    module.hot.accept("./AposAvatar.vue?vue&type=template&id=107c48b7&scoped=true&", function () {
      api.rerender('107c48b7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ui/ui/apos/components/AposAvatar.vue"
export default component.exports